// Makes the header sticky. Use this together with the fixed class.
export function initStickyHeader() {
  const header = document.querySelector(".header")
  const body = document.body
  const html = document.documentElement
  const user = header.querySelector(".header__action--user")

  const height = Math.max(body.scrollHeight, body.offsetHeight,
    html.clientHeight, html.scrollHeight, html.offsetHeight)

  const hasHeroBanner = Boolean(document.querySelector(".hero-banner"))

  if (!hasHeroBanner) {
    header.classList.add("header--sticky")
    header.classList.add("position-sticky")
    user.classList.add("header__action--user--logged-in-green")
  }

  function stickyHeader() {

    if (height > window.screen.height && window.scrollY > 1) {
      header.classList.add("header--sticky")
      user.classList.add("header__action--user--logged-in-green")
    }
    else if (height > window.screen.height && window.scrollY === 0) {
      if (!hasHeroBanner) {
        header.classList.add("position-fixed")
      } else {
        header.classList.remove("header--sticky")
        user.classList.remove("header__action--user--logged-in-green")
      }
    }
  }

  window.addEventListener("scroll", stickyHeader)
}
