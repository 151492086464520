/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//

// jQuery is added strictly for the Analytics (gya.js)
import $ from "jquery"
window.$ = window.jQuery = $

import "bootstrap/js/dist/modal"

import { bindGYEvents } from "../gy-events"
import { startRails, freezeBackground, unfreezeBackground } from "../shared"
import { responsiveMenu } from "../header/mainMenu"
import { initStickyHeader } from "../header/header"
import { initSearch } from "../header/search"
// import { initCurrencyPopUp } from "../header/currency" (Temporarily disabled)
import { initScrollUp } from "../buttons/scrollUp"
import { initMiniCartPanel } from "../pages/miniCart"
import { initActiveCurrency } from "../components/currencyPopUp"

const GY = {
  loadShared: function () {
    startRails()
    bindGYEvents()
    responsiveMenu()
    freezeBackground()
    unfreezeBackground()
    initStickyHeader()
    initSearch()
    // initCurrencyPopUp() (Temporarily disabled)
    initMiniCartPanel()
    initActiveCurrency()
    initScrollUp()
  },
  loadHomepage: function () {
    import("../initializers/homepageInitializer")
      .then((module) => { module.homepageInitializer() })
  },
  loadCatalog: function () {
    import("../initializers/catalogInitializer")
      .then((module) => { module.catalogInitializer() })
  },
  loadExpertsPage: function () {
    import("../initializers/expertsPageInitializer")
      .then((module) => { module.expertsPageInitializer() })
  },
  loadStaticPages: function () {
    import("../initializers/staticPagesInitializer")
      .then((module) => { module.staticPagesInitializer() })
  },
  loadOrder: function () {
    import("../initializers/orderInitializer")
      .then((module) => { module.staticPagesInitializer() })
  },
  loadCheckout: function () {
    import("../initializers/checkoutInitializer")
      .then((module) => { module.checkoutInitializer() })
  }
}

GY.loadShared()

if (document.querySelector(".homepage")) {
  import("bootstrap/js/dist/tab")
  import("bootstrap/js/dist/dropdown")

  GY.loadHomepage()
}
else if (document.querySelector(".product-page")) {
  import("bootstrap/js/dist/tab")
  import("bootstrap/js/dist/dropdown")
}
else if (document.querySelector(".catalog-page")) {
  import("bootstrap/js/dist/dropdown")

  GY.loadCatalog()
}
else if (document.querySelector(".experts-page")) {
  import("bootstrap/js/dist/collapse")
  import("bootstrap/js/dist/dropdown")

  GY.loadExpertsPage()
}
else if (document.querySelector(".static-page")) {
  GY.loadStaticPages()
}
else if (document.querySelector(".checkout-2")) {
  GY.loadCheckout()
}
else if (document.querySelector(".wishlist-page")) {
  import("bootstrap/js/dist/dropdown")
}
