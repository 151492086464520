export function initScrollUp() {
  const scrollUp = document.querySelector('.footer__scroll-up')

  if (scrollUp) {
    scrollUp.addEventListener('click', (e) => {
      e.preventDefault()

      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    })
  }
}