// Adds the functionality for the responsive menu. Open, close, nextlevel and backlevel
export function responsiveMenu() {
  const mainMenu = document.querySelector(".main-menu")
  const menuTrigger = document.querySelector(".menu-trigger")
  const closeMenu = mainMenu.querySelector(".main-menu__close")
  const menuItemHasChildren = mainMenu.querySelectorAll(".menu-item-has-children")

  menuTrigger.addEventListener("click", (e) => {
    e.preventDefault()

    mainMenu.classList.add("main-menu--open")
    freezeBackground()
  })

  closeMenu.addEventListener("click", (e) => {
    e.preventDefault()

    mainMenu.classList.remove("main-menu--open")
    unfreezeBackground()
  })

  menuItemHasChildren.forEach(level => {

    level.addEventListener("click", function () {
      this.classList.toggle("active-menu-level")
      this.querySelector(".main-menu__l2 ").classList.toggle("d-none")
    })

    level.querySelector("a").addEventListener("click", (e) => e.stopPropagation())
  })
}