export class YoutubeVideo {
  constructor(selector = ".youtube-video") {
    const elements = document.querySelectorAll(selector)

    this.embedVideo(elements)
  }

  embedVideo(youtube) {
    youtube.forEach((youtubeVideo, index) => {
      youtubeVideo.addEventListener("click", function () {
        let ifrm = document.createElement("iframe")

        ifrm.setAttribute("src", `https://www.youtube.com/embed/${this.dataset.embed}?rel=0&showinfo=0&autoplay=1`)
        ifrm.setAttribute("allow", "autoplay")

        this.append(ifrm)
      }, { once: true })
    })
  }
}
