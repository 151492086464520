export function initActiveCurrency() {
  const currencies = document.querySelectorAll(".currency-popup__currencies-item")

  currencies.forEach(currency => {
    currency.addEventListener('click', function () {
      currencies.forEach(currency => {
        currency.classList.remove('active')
      })
      currency.classList.add('active')
    })
  })
}