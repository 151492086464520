import Drift from 'drift-zoom';

export function initDriftZoom() {
  // Zoom on Desktop
  if (window.innerWidth > 1200) {
    const productImages = document.querySelectorAll('.product-page__product-image')

    productImages.forEach(imgSelector => {

      new Drift(imgSelector, {
        paneContainer: document.querySelector(".zoom-pane"),
      })
    })
  }

  // Zoom on Mobile
  const product = document.querySelector('.product-page')
  const zoom = product.querySelector('.zoom__button--mobile')

  zoom.addEventListener('click', function () {
    let activeImage;

    if (product.querySelectorAll('.product-page__main-photo .swiper-slide').length > 1) {
      activeImage = product.querySelector('.product-page__main-photo .swiper-slide-active img').getAttribute('data-zoom')
    }
    else {
      activeImage = product.querySelector('.product-page__main-photo .swiper-slide img').getAttribute('data-zoom')
    }

    product.querySelector('#zoomModal .modal-body img').setAttribute("src", activeImage)
  })

  product.querySelector('#zoomModal').addEventListener('hidden.bs.modal', function () {
    product.querySelector('#zoomModal .modal-body img').setAttribute("src", '')
  })
}