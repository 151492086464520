import Swiper, { Autoplay, Navigation } from "swiper"

Swiper.use([Autoplay, Navigation])

export function initHomepageSlider() {
  const sliderSection = document.querySelector(".homepage__slider")

  if (sliderSection) {
    new Swiper(sliderSection.querySelector(".swiper"), {
      slidesPerView: 1,
      watchOverflow: true,
      speed: 500,
      autoplay: {
        delay: 3000
      }
    })
  }
}

export function initAncientSlider() {
  const sliderSection = document.querySelector(".homepage__ancient-greece")

  if (sliderSection) {
    new Swiper(sliderSection.querySelector(".swiper"), {
      slidesPerView: 2,
      slidesPerGroup: 2,
      preloadImages: false,
      speed: 500,
      watchOverflow: true,
      navigation: {
        nextEl: sliderSection.querySelector('.btn-pagination--right'),
        prevEl: sliderSection.querySelector('.btn-pagination--left')
      }
    })
  }
}

export function initHomepagePresentsTabSlider() {
  function initHomepagePresentsSlider() {
    setTimeout(() => {
      const homepagePresentsSlider = document.querySelector(".homepage__presents")

      if (homepagePresentsSlider) {
        let initial_tab = homepagePresentsSlider.querySelector(".tab-pane.active")
        initHomepagePresents(initial_tab)

        homepagePresentsSlider.querySelectorAll("button[data-bs-toggle='tab']").forEach(tab => {
          tab.addEventListener("shown.bs.tab", function () {
            let getId = tab.getAttribute("data-bs-target")
            let active_tab = homepagePresentsSlider.querySelector(`.tab-pane${getId}`)

            initHomepagePresents(active_tab)
          }, { once: true })
        })
      }
    }, 300)
  }

  function initHomepagePresents(active_tab) {
    setTimeout(() => {
      const sliderSection = active_tab.querySelector(".swiper")

      if (sliderSection) {
        new Swiper(sliderSection, {
          slidesPerView: 2,
          slidesPerGroup: 2,
          speed: 500,
          watchOverflow: true,
          navigation: {
            nextEl: active_tab.querySelector('.btn-pagination--right'),
            prevEl: active_tab.querySelector('.btn-pagination--left')
          },
          breakpoints: {
            1200: {
              slidesPerView: 3,
              slidesPerGroup: 3
            }
          }
        })
      }
    })
  }

  initHomepagePresentsSlider()
}

export function initHomepageElegantDominanceTabSlider() {
  function initHomepageElegantDominanceSlider() {
    setTimeout(() => {
      const homepageElegantDominanceSlider = document.querySelector(".homepage__elegant-dominance")
      if (homepageElegantDominanceSlider) {
        let initial_tab = homepageElegantDominanceSlider.querySelector(".tab-pane.active")
        initHomepagePresents(initial_tab)

        homepageElegantDominanceSlider.querySelectorAll("button[data-bs-toggle='tab']").forEach(tab => {
          tab.addEventListener("shown.bs.tab", function () {
            let getId = tab.getAttribute("data-bs-target")
            let active_tab = homepageElegantDominanceSlider.querySelector(`.tab-pane${getId}`)
            initHomepagePresents(active_tab)
          }, { once: true })
        })
      }
    }, 300)
  }

  function initHomepagePresents(active_tab) {
    setTimeout(() => {
      const sliderSection = active_tab.querySelector(".swiper")

      if (sliderSection) {
        new Swiper(sliderSection, {
          slidesPerView: 2,
          slidesPerGroup: 2,
          speed: 500,
          preloadImages: false,
          watchOverflow: true,
          navigation: {
            nextEl: document.querySelector('.homepage__elegant-dominance .btn-pagination--right'),
            prevEl: document.querySelector('.homepage__elegant-dominance .btn-pagination--left')
          }
        })
      }
    })
  }

  initHomepageElegantDominanceSlider()
}

export function initMainProductSlider() {
  setTimeout(() => {
    const slider = document.querySelector(".product-page__main-photo")

    if (slider.querySelectorAll(".swiper-slide").length > 1) {
      new Swiper(slider, {
        slidesPerView: 1,
        slidesPerGroup: 1,
        watchOverflow: true,
        speed: 500,
        preloadImages: false,
        navigation: {
          nextEl: slider.querySelector('.btn-pagination--right'),
          prevEl: slider.querySelector('.btn-pagination--left'),
        }
      })
    }
  }, 1000)
}

export function initProductPageRelatedProductsSlider() {
  const slider = document.querySelector(".product-page__related")

  if (slider) {
    new Swiper(slider.querySelector(".swiper"), {
      slidesPerView: 3,
      slidesPerGroup: 3,
      watchOverflow: true,
      speed: 500,
      preloadImages: false,
      navigation: {
        nextEl: slider.querySelector('.btn-pagination--right'),
        prevEl: slider.querySelector('.btn-pagination--left'),
      }
    })
  }
}
